<template>
  <div class="layout-container absolute right-0 left-0 z-10 px-0 md:px-56">
    <div
      class="breadcrumbs flex md:h-56 h-40 items-center truncate pl-24 md:pl-0"
    >
      <div
        v-for="(route, index) in breadcrumbs"
        :key="route.RelativePath"
        class="flex items-center flex-shrink-0 truncate"
      >
        <div
          class="meta meta-info-text uppercase transition-colors ease-in-out duration-200"
          :class="{
            'text-grey600': hoveredLink && hoveredLink !== route.RelativePath,
          }"
          @mouseenter="onHoverEnter(route.RelativePath)"
          @mouseleave="onHoverLeave()"
        >
          <nuxt-link :to="route.RelativePath">
            {{ route.NameInBreadcrumbs || route.Name }}
          </nuxt-link>
        </div>
        <img
          src="~/assets/icons/arrow-forward.png"
          class="mt-2 mx-8 h-16"
          height="16"
          width="16"
        />
      </div>
      <div
        class="meta meta-info-text uppercase transition-colors ease-in-out duration-200 pr-16"
        :class="{
          'text-grey600': hoveredLink,
        }"
      >
        {{ title }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';

const globalContentStore = useGlobalContentStore();
const props = defineProps<{
  breadcrumbs: any[],
  title: string,
}>();

const hoveredLink = ref('');

const onHoverEnter = (link: string) => {
  hoveredLink.value = link;
};

const onHoverLeave = () => {
  hoveredLink.value = '';
};

const breadcrumbsValue = props.breadcrumbs;
const host = globalContentStore.host;

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbsValue.map((item, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': host + item.RelativePath,
        name: item.nameInBreadCrumbs || item.Name,
      },
    };
  }),
} as any;

useJsonld(jsonLd);

</script>
<style>
.breadcrumbs {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  .meta:last-child {
    a {
      margin-right: 20px;
    }
  }
}

.breadcrumbs::-webkit-scrollbar {
  display: none;
}
</style>
